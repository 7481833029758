import * as React from "react";
import qrAright from "../images/roundArightAnswer.png";
import qrAwrong from "../images/roundAwrongAnswer.png";
import qrBright from "../images/roundBrightAnswer.png";
import qrBwrong from "../images/roundBwrongAnswer.png";

// markup
/** @ts-ignore */
const CodesPage = ({ location }) => {
  return (
    <main>
      <title>YouTube Quiz</title>
      <h1>Persistence Test // QR Codes</h1>
      <h3 style={{ marginTop: "100px" }}>Round A</h3>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "50%",
          }}
        >
          <p style={{ textAlign: "center" }}>
            <img src="/roundArightAnswer.png" alt="" />
          </p>
          <p style={{ textAlign: "center" }}>Right ✅</p>
        </div>
        <div
          style={{
            width: "50%",
          }}
        >
          <p style={{ textAlign: "center" }}>
            <img src="/roundAwrongAnswer.png" alt="" />
          </p>
          <p style={{ textAlign: "center" }}>Wrong ❌</p>
        </div>
      </div>
      <h3 style={{ marginTop: "100px" }}>Round B</h3>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "50%",
          }}
        >
          <p style={{ textAlign: "center" }}>
            <img src="/roundBrightAnswer.png" alt="" />
          </p>
          <p style={{ textAlign: "center" }}>Right ✅</p>
        </div>
        <div
          style={{
            width: "50%",
          }}
        >
          <p style={{ textAlign: "center" }}>
            <img src="/roundBwrongAnswer.png" alt="" />
          </p>
          <p style={{ textAlign: "center" }}>Wrong ❌</p>
        </div>
      </div>
    </main>
  );
};

export default CodesPage;
